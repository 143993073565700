import React from "react";

import styled from "styled-components";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'

const Icon = styled.div`
    margin-right: 5px;
    display: inline-block;
    color: black;
    background: white;
    padding: 5px;
    font-size: 20px;
    width: 30px;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
    transition-duration: 0.4s;

    &:hover {
        opacity: 0.7;
  }
`;

const IconGroup = styled.div`
    text-align: right;
    padding-right: 15px;
    padding-top: 25px;
`;

const NavigationMediaLinks = () => {
    return (  
        <IconGroup>
        <a href="https://facebook.com" target="_blank" rel="noopener noreferrer"><Icon><FontAwesomeIcon icon={faFacebookF}/></Icon></a>
        <a href="https://instagram.com/glamourwigsbyariella" target="_blank" rel="noopener noreferrer"><Icon><FontAwesomeIcon icon={faInstagram}/></Icon></a>
        <a href="mailto:Glamourcustomwigs@gmail.com"><Icon><FontAwesomeIcon icon={faEnvelope}/></Icon></a>
        </IconGroup>
    );
}
 
export default NavigationMediaLinks;


