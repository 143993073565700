import React, { useState, useRef }  from "react";
import styled from "styled-components";
import SimpleReactValidator from 'simple-react-validator';
import EmailService from "../services/EmailService";

const Background = styled.div`
  width: 100%;
  min-height: 70vh;
`;

const Title = styled.p`
    margin-left: 25px;
    color: #C79732;
    font-size: 2rem;
    font-family: "Gruppo" ;
`;

const TextField = styled.p`
    margin-bottom: 5px;
    margin-top: 5px;
    margin-left: 25px;
    color: #C79732;
    font-size: 1.5rem;
    font-family: "Catamaran" ;
`;

const FormElement = styled.div`
  padding-left: 30px;
  padding-bottom: 20px;
  label{
      color: #C79732;
      display:block;
  }
  input{
      background-color: #FFF2CC;
      width: 250px;
      font-size: 1rem;
  }
  textarea{
    background-color: #FFF2CC;
    width: 350px;
    height: 100px;
  }

  .srv-validation-message{
      color: red;
  }
`;

const FormElementRadio = styled.div`
  padding-left: 30px;
  padding-bottom: 20px;

  p{
      color: #C79732;
      margin-bottom: 3px;
    }

  label{
    display:inline-block;
    color: black;
    cursor: pointer;
    padding: 10px 10px;
    background-color: #C79732;
    margin-right: 3px;
    margin-bottom: 5px; 
    border: 1.5px solid gray;
    border-radius: 5px;
    transition-duration: 0.4s;
    &:hover {
        background-color: #FFF2CC;
    }
  }

  input[type="radio"]:checked+label{ 
      background-color: #FFF2CC;
    }

  input{
      display: none;
      background-color: #FFF2CC;
      width: 250px;
      font-size: 1rem;
  }
`;

const SubmitButton = styled.div`
    margin-left: 30px;
    margin-bottom: 30px;
    text-align: center;
    width: 250px;
    padding: 5px 5px;
    font-family: "PT Sans", serif;
    font-weight: bold;
    background-color: #C79732;
    font-size: 1rem;
    transition-duration: 0.4s;
    cursor: pointer;
    &:hover {
        background-color: #FFF2CC;
  }
`;

const OrderForm = () => {
    const validator = useRef(new SimpleReactValidator())
    const [, forceUpdate] = useState();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");
    const [style, setStyle] = useState("straight");
    const [capSize, setCapSize] = useState("small");
    const [length, setLength] = useState("10inch");
    const [color, setColor] = useState("brunette");
    const [wigSelection, setWigSelection] = useState("Silk Top Wig");
    const [success, setSuccess] = useState("");
    const [btnShow, setBtnShow] = useState(true);

    function submitForm() {
        if (validator.current.allValid()) {
          EmailService.customOrder(name, email, phone, message, wigSelection, length, capSize, color, style).then(() => {
            setSuccess("You have successfully submitted your custom order, we will review your request and follow up within 1-3 business days. Thank you.");
            setBtnShow(false);
          },
          (error) => {
              console.log(error);
              setSuccess("There was an error processing your order! Please contact Glamourcustomwigs@gmail.com with your order.");
              setBtnShow(false);
          })
        } else {
          validator.current.showMessages();
          // rerender to show messages for the first time
          // you can use the autoForceUpdate option to do this automatically`
          forceUpdate(1);
        }
      }

    return (  
        <Background>
            <Title>
                CUSTOM ORDER FORM
            </Title>

        <FormElementRadio>
        <p>Wig Selection: </p>
        <br></br>
        <input onChange={(e) => setWigSelection(e.target.value)} type="radio" id="Silk Top Wig" name="selection" value="Silk Top Wig" defaultChecked/>
        <label htmlFor="Silk Top Wig">Silk Top Wig</label>
        <input onChange={(e) => setWigSelection(e.target.value)} type="radio" id="Lace Top Wig" name="selection" value="Lace Top Wig"/>
        <label htmlFor="Lace Top Wig">Lace Top Wig</label>
        <input onChange={(e) => setWigSelection(e.target.value)} type="radio" id="Silk Top Wig with Lace Front" name="selection" value="Silk Top Wig with Lace Front"/>
        <label htmlFor="Silk Top Wig with Lace Front">Silk Top Wig with Lace Front</label>
        <input onChange={(e) => setWigSelection(e.target.value)} type="radio" id="Band Fall" name="selection" value="Band Fall"/>
        <label htmlFor="Band Fall">Band Fall</label>
        </FormElementRadio>

        <FormElementRadio>
        <p>Length: </p>
        <br></br>
        <input onChange={(e) => setLength(e.target.value)} type="radio" id="10inch" name="length" value="10inch" defaultChecked/>
        <label htmlFor="10inch">10 Inch</label>
        <input onChange={(e) => setLength(e.target.value)} type="radio" id="12inch" name="length" value="12inch"/>
        <label htmlFor="12inch">12 Inch</label>
        <input onChange={(e) => setLength(e.target.value)} type="radio" id="14inch" name="length" value="14inch"/>
        <label htmlFor="14inch">14 Inch</label>
        <input onChange={(e) => setLength(e.target.value)} type="radio" id="16inch" name="length" value="16inch"/>
        <label htmlFor="16inch">16 Inch</label>
        <input onChange={(e) => setLength(e.target.value)} type="radio" id="18inch" name="length" value="18inch"/>
        <label htmlFor="18inch">18 Inch</label>
        <input onChange={(e) => setLength(e.target.value)} type="radio" id="20inch" name="length" value="20inch"/>
        <label htmlFor="20inch">20 Inch</label>
        <input onChange={(e) => setLength(e.target.value)} type="radio" id="22inch" name="length" value="22inch"/>
        <label htmlFor="22inch">22 Inch</label>
        <input onChange={(e) => setLength(e.target.value)} type="radio" id="24inch" name="length" value="24inch"/>
        <label htmlFor="24inch">24 Inch</label>
        <input onChange={(e) => setLength(e.target.value)} type="radio" id="26inch" name="length" value="26inch"/>
        <label htmlFor="26inch">26 Inch</label>
        </FormElementRadio>

        <FormElementRadio>
        <p>Cap Size: </p>
        <br></br>
        <input onChange={(e) => setCapSize(e.target.value)} type="radio" id="small" name="capsize" value="small" defaultChecked/>
        <label htmlFor="small">Small</label>
        <input onChange={(e) => setCapSize(e.target.value)} type="radio" id="medium" name="capsize" value="medium"/>
        <label htmlFor="medium">Medium</label>
        <input onChange={(e) => setCapSize(e.target.value)} type="radio" id="large" name="capsize" value="large"/>
        <label htmlFor="large">Large</label>
        </FormElementRadio>

        <FormElementRadio>
        <p>Color: </p>
        <br></br>
        <input onChange={(e) => setColor(e.target.value)} type="radio" id="brunette" name="color" value="brunette" defaultChecked/>
        <label htmlFor="brunette">Brunette</label>
        <input onChange={(e) => setColor(e.target.value)} type="radio" id="blonde" name="color" value="blonde"/>
        <label htmlFor="blonde">Blonde</label>
        <input onChange={(e) => setColor(e.target.value)} type="radio" id="black" name="color" value="black"/>
        <label htmlFor="black">Black</label>
        </FormElementRadio>

        <FormElementRadio>
        <p>Style: </p>
        <br></br>
        <input onChange={(e) => setStyle(e.target.value)} type="radio" id="straight" name="style" value="straight" defaultChecked/>
        <label htmlFor="straight">Straight</label>
        <input onChange={(e) => setStyle(e.target.value)} type="radio" id="curly" name="style" value="curly"/>
        <label htmlFor="curly">Curly</label>
        <input onChange={(e) => setStyle(e.target.value)} type="radio" id="wavy" name="style" value="wavy"/>
        <label htmlFor="wavy">Wavy</label>
        </FormElementRadio>

        <FormElement>
        <label>Name: </label>
        <input value={name} onChange={(e) => setName(e.target.value)} />
        {validator.current.message('name', name, 'required|alpha_space')}
        </FormElement>

        <FormElement>
        <label>Email: </label>
        <input value={email} onChange={(e) => setEmail(e.target.value)} />
        {validator.current.message('email', email, 'required|email')}
        </FormElement>

        <FormElement>
        <label>Phone: </label>
        <input value={phone} onChange={(e) => setPhone(e.target.value)} />
        {validator.current.message('phone', phone, 'required|phone')}
        </FormElement>

        <FormElement>
        <label>Comments (Any other specific details desired): </label>
        <textarea value={message} onChange={(e) => setMessage(e.target.value)} />
        {validator.current.message('message', message, 'max:300')}
        </FormElement>

        {btnShow ? <SubmitButton onClick={submitForm}>Submit</SubmitButton> : <TextField>{success}</TextField>}

        </Background>
    );
}
 
export default OrderForm;