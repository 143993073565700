import React from "react";
import styled from "styled-components";

import ServicesItem from "./ServicesComponents/ServicesItem"

const Background = styled.div`
    width: 100%;
    min-height: 70vh;
`;

const ItemBoxHolder = styled.div`
    text-align: center;
    max-width: 1500px;
    margin: 0 auto;
`;

const Title = styled.p`
    margin-left: 25px;
    color: #C79732;
    font-size: 2rem;
    font-family: "Gruppo" ;
`;

const TextField = styled.p`
    margin-left: 25px;
    color: #C79732;
    font-size: 1.5rem;
    font-family: "Catamaran" ;
`;

const Services = () => {
    return (  
        <Background>
            <Title>
                SERVICES
            </Title>
            <TextField>Select the service you need, schedule your appointment and a team member will reach out to you to confirm your personalized Glam service.</TextField>
                <ItemBoxHolder>
                <ServicesItem title="10 MIN CONSULTATION BOCA RATON, FL" link="https://squareup.com/appointments/book/6tyc59h3ehu5s4/LJWAAH7QH24TV/services"/>
                <ServicesItem title="1 HR IN PERSON WIG CUSTOMIZATION BOCA RATON, FL" link="https://squareup.com/appointments/book/d06pp5uwtymiwk/LJWAAH7QH24TV/services"/>
                <ServicesItem title="10 MIN VIRTUAL CONSULTATION" link="https://squareup.com/appointments/book/d5n51e46l52u96/LJWAAH7QH24TV/services"/>
                <ServicesItem title="LACE FRONT INSTALL" link="https://squareup.com/appointments/book/8i2p360wop6mds/LJWAAH7QH24TV/services"/>
                <ServicesItem title="DEEP LACE INSTALL" link="https://squareup.com/appointments/buyer/widget/tgeyopp6fijw18/LJWAAH7QH24TV/services"/>
                <ServicesItem title="BABY HAIRS" link="https://squareup.com/appointments/book/7m5qphhbj0qoss/LJWAAH7QH24TV/services"/>
                <ServicesItem title="BASIC STYLE (NO WASH)" link="https://squareup.com/appointments/book/76gbgjbaufk0gb/LJWAAH7QH24TV/services"/>
                <ServicesItem title="BASIC WASH & STYLE" link="https://squareup.com/appointments/book/1wtalpqxk216dd/LJWAAH7QH24TV/services"/>
                <ServicesItem title="WASH ONLY" link="https://squareup.com/appointments/book/ch03xc4tf3dymh/LJWAAH7QH24TV/services"/>
                <ServicesItem title="SPECIAL OCCASION STYLE" link="https://squareup.com/appointments/book/sdpks1xoy448td/LJWAAH7QH24TV/services"/>
                </ItemBoxHolder>
        </Background>
    );
}
 
export default Services;