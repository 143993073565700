import React from "react";
import styled from "styled-components";

const Background = styled.div`
    width: 100%;
    min-height: 70vh;
`;

const Title = styled.p`
    margin-left: 25px;
    color: #C79732;
    font-size: 2rem;
    font-family: "Gruppo" ;
`;

const TextField = styled.p`
    margin-left: 25px;
    margin-right: 50px;
    color: #C79732;
    font-size: 1.5rem;
    font-family: "Catamaran" ;
`;

const About = () => {
    return (  
        <Background>
            <Title>
                ABOUT
            </Title>
            <TextField>
            Our story starts with Ariella who always looks for meaning and purpose in life. When Ariella got married, her hair was something that was so hard for her to cover because she did not want to give up what made her feel beautiful. When she started to go wig shopping, she couldn’t find the right color, size or style, and she definitely wasn’t willing to pay top dollar for a custom wig. She was disappointed on how the wigs were not made to fit every person individually. Ariella ended up getting a wig that was not the right color or style for her and felt tight on her head. She just wasn’t happy with the experience and wanted to give up on covering her hair.
            </TextField>
            <TextField>
            It didn’t feel right to her that someone should have to settle for a wig they may not even like, and she felt it was her duty to change that. Ariella got inspired and learned the craft of wig making, repairs and styling. She then went on to make her own wig company that would make sure that every woman would be able to get the wig they love for a price they could afford. This would make it easier for everyone to cover their hair. Ariella makes sure that everyone who comes to her gets a wig that’s crafted just for them. She has a selection of custom-made colors, color blends and even allows women to show her a picture with exactly what they want, and she makes it her mission to replicate it. Ariella truly cares about people and will make sure your head is covered the way that makes you happy.
            </TextField>
            <Title>
                MISSION
            </Title>
            <TextField>
            Our mission is to provide customers with high-quality custom-made wigs and professional services.
            </TextField>
            <Title>
                VISION
            </Title>
            <TextField>
            Our vision is to create a better head covering experience for every woman and shed some more light into the world.
            </TextField>
        </Background>
    );
}
 
export default About;