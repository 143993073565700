import React from "react";
import styled from "styled-components";

const ItemBox = styled.div`
  background-color: white;
  width: 200px;
  height: 200px;
  margin: 25px 25px;
  display: inline-block;
  cursor: pointer;
  vertical-align:top;
  transition-duration: 0.4s;
  border: 3px solid transparent;

  &:hover {
    background-color: black;
    border: 3px solid #C79732;
  }

  &:hover p{
    color: #C79732;
  }
`;

const TextField = styled.p`
    position: relative;
    top: 30%;
    -ms-transform: translateY(-30%);
    -webkit-transform: translateY(-30%);
    transform: translateY(-30%);
    color: black;
    font-weight: bold;
    font-size: 1.25rem;
    font-family: "Catamaran" ;
    text-align: center;
    margin-left: 10px;
    margin-right: 10px;
`;

const ServicesItem = ({title, link}) => {
    return (  
        <ItemBox onClick={()=> window.open(link, "_blank")}>
            <TextField>{title}</TextField>
        </ItemBox>
    );
}
 
export default ServicesItem;