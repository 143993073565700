import React from "react";
import styled from "styled-components";

const FooterHold = styled.div`
    margin-top: 50px;
`;

const FooterText = styled.p`
    text-align: center;
    color: #C79732;
    font-size: 0.9rem;
    font-family: "Chilanka" ;
`;

const Footer = () => {
    return (  
        <FooterHold>
            <FooterText>
                Copyright © 2020 Glamour Wigs. All rights reserved. 
            </FooterText>
        </FooterHold>
    );
}
 
export default Footer;