import React from "react";
import styled from "styled-components";

import OurWigsItem from "./OurWigsComponents/OurWigsItem"

import LaceTop from '../assets/img/ourWig-laceTop.jpeg'
import BandFall from '../assets/img/ourWig-bandFall.jpeg'
import SilkTopLaceFront from '../assets/img/ourWig-silkTopLaceFront.jpeg'
import SilkTop from '../assets/img/ourWig-silkTop.jpeg'

const Background = styled.div`
  width: 100%;
  min-height: 70vh;
  margin-bottom: 50px;
`;

const ItemBoxHolder = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    margin: 0 auto;
    width: 90%;
`;

const Title = styled.p`
    margin-left: 25px;
    color: #C79732;
    font-size: 2rem;
    font-family: "Gruppo" ;
`;

const OurWigs = () => {
    return (  
        <Background>
            <Title>
                OUR WIGS
            </Title>
                <ItemBoxHolder>
                <OurWigsItem 
                title = "Lace Top Wig" 
                desc = "The lace top wig gives the most natural look among all the wig types. The hair is individually hand tied to the lace which means that it appears that the hair is coming from your own scalp. This is unique among the wigs, in that it’s the naturalist piece you can get and is our company favorite."
                img = {LaceTop}
                />
                <OurWigsItem 
                title = "Band Fall Wig" 
                desc = "The band fall wig is uniquely versatile. It can be worn with a headband, a hat, a head piece and the list goes on... We especially love this piece because for many it is more comfortable and easier to wear on a daily basis. This piece is definitely a must have."
                img = {BandFall}
                />
                <OurWigsItem 
                title = "Silk Top Wig with Lace Front" 
                desc = "This is a classic silk top wig that has a lace front added to it. A lace front is a method of adding a more natural look to your wig. It makes the hair in the front of the wig appear to be coming from your own scalp. This is great if you are seeking a piece with a more natural looking front."
                img = {SilkTopLaceFront}
                />
                <OurWigsItem 
                title = "Silk Top Wig" 
                desc = "A silk top wig is a multi-directional wig that is a classic and has been around for many years. The top is made of silk and you can part it any you want. It creates a realistic hairline. This wig is perfect for someone who is looking for a wig that is easier to take care of, is multidirectional and lasts longer with the proper care."
                img = {SilkTop}
                />
                </ItemBoxHolder>
            </Background>
    );
}
 
export default OurWigs;