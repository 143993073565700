import axios from "axios";

const API_URL = "https://glamourwigsemail.uk.r.appspot.com/email/";

//All methods relating to emails such as the order form and contact form
class EmailService {
    contact(name, email, phone, message) {
      return axios
        .post(API_URL + "contact", {
            name, email, phone, message
        }).then((response) => {
            return response.data;
          });
    }

    customOrder(name, email, phone, comment, wig, length, size, color, style) {
        return axios
          .post(API_URL + "orderform", {
            name, email, phone, comment, wig, length, size, color, style
          }).then((response) => {
            return response.data;
          });
      }
}

export default new EmailService();