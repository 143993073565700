import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import './App.css';

import About from './components/About';
import Contact from './components/Contact';
import Homepage from './components/Homepage';
import OrderForm from './components/OrderForm';
import OurWigs from './components/OurWigs';
import Services from './components/Services';
import Navigation from './components/Navigation'
import Footer from './components/Footer'

function App() {
  return (
    <Router>
      <div className="App">
       <Navigation/> 
       <Switch>
       <Route exact path="/" component={Homepage} />
       <Route exact path="/about" component={About}/>
       <Route exact path="/contact" component={Contact}/>
       <Route exact path="/order" component={OrderForm}/>
       <Route exact path="/wigs" component={OurWigs}/>
       <Route exact path="/services" component={Services}/>
       </Switch>
      </div>
      <Footer/>
    </Router>
  );
}

export default App;
