import React from "react";
import styled from "styled-components";

const ItemBox = styled.div`
  width: 300px;
  height: 700px;
  margin-left: 40px;
  margin-right: 40px;
  margin-bottom: 100px;
`;

const WigImage = styled.img`
  width: 300px;
  height: 400px;
`

const TextField = styled.p`
    color: #C79732;
    font-size: 1.25rem;
    font-family: "Catamaran" ;
    text-align: left;
`;

const OurWigsItem = ({title, desc, img}) => {
    return (  
        <ItemBox>
            <WigImage src={img} alt={title} />
            <TextField>{title}</TextField>
            <TextField>{desc}</TextField>
        </ItemBox>
    );
}
 
export default OurWigsItem;