import React from "react";
import styled from "styled-components";
import logo from '../assets/img/logo.png'
import { NavLink } from "react-router-dom";

import NavigationMediaLinks from "./NavigationComponents/NavigationMediaLinks"

const Background = styled.div`
  width: 100%;
  background: black;
`;

const AppLogo = styled.img`
  padding-top: 10px;
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 500px;
`

const NavButton = styled.p`
  display: inline-block;
  font-family: "PT Sans", serif;
  color: #C79732;
  font-size: 1.75rem;
  cursor: pointer;
  transition-duration: 0.4s;
  
  &:hover {
    color: #FFF2CC;
  }
`;

const Divider = styled.div`
  background-color: #C79732;
  display: inline-block;
  width: 2px;
  margin: 0 10px;
  height: 1.75rem;
  vertical-align: sub;
`;

const NavHolder = styled.div`
    text-align: center;
`;

const Navigation = () => {
    return (  
        <Background>
            <NavigationMediaLinks/>
           <AppLogo src={logo} alt="logo" />
           <NavHolder>
           <NavLink to="/" style={{ textDecoration: 'none' }}><NavButton>HOME</NavButton></NavLink>
            <Divider/>
            <NavLink to="/about" style={{ textDecoration: 'none' }}><NavButton>ABOUT</NavButton></NavLink>
            <Divider/>
            <NavLink to="/wigs" style={{ textDecoration: 'none' }}><NavButton>OUR WIGS</NavButton></NavLink>
            <Divider/>
            <NavLink to="/order" style={{ textDecoration: 'none' }}><NavButton>CUSTOM ORDER FORM</NavButton></NavLink>
            <Divider/>
            <NavLink to="/services" style={{ textDecoration: 'none' }}><NavButton>SERVICES</NavButton></NavLink>
            <Divider/>
            <NavLink to="/contact" style={{ textDecoration: 'none' }}><NavButton>CONTACT</NavButton></NavLink>
            </NavHolder>
        </Background>
    );
}
 
export default Navigation;