import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import carousel1 from '../assets/img/1_carousel.jpeg'
import carousel2 from '../assets/img/2_carousel.jpeg'
import carousel3 from '../assets/img/3_carousel.jpeg'
import carousel4 from '../assets/img/4_carousel.jpeg'

const Background = styled.div`
  width: 100%;
  padding-bottom: 30px;
`;

const Slogan = styled.p`
    color: #C79732;
    font-size: 2.5rem;
    font-family: "Catamaran" ;
    text-align: center;
    margin-left: 25px;
`

const TextField = styled.p`
    color: #C79732;
    font-size: 1.5rem;
    font-family: "Catamaran" ;
    text-align: center;
    margin-left: 25px;
`

const OrderButton = styled.div`
    margin: 0 auto;
    text-align: center;
    width: 300px;
    padding: 15px 15px;
    font-family: "PT Sans", serif;
    background-color: #C79732;
    font-size: 1.5rem;
    transition-duration: 0.4s;
    cursor: pointer;
    &:hover {
        background-color: #FFF2CC;
  }
    a{
        color: black;
    }
`;

const Homepage = () => {
    return (  
        <Background>
            <Carousel autoPlay infiniteLoop={true} stopOnHover={true} swipeable={true} emulateTouch={true} showStatus={false} showThumbs={false} interval = {3000} transitionTime={750}>
                <div>
                    <img src={carousel1} alt="carousel_1"/>
                </div>
                <div>
                    <img src={carousel2} alt="carousel_2"/>
                </div>
                <div>
                    <img src={carousel3} alt="carousel_3"/>
                </div>
                <div>
                    <img src={carousel4} alt="carousel_4"/>
                </div>
            </Carousel>
            <Slogan>“We’ve got your head covered” (literally)</Slogan>
            <OrderButton><NavLink to="/order" style={{ textDecoration: 'none' }}>DESIGN MY CUSTOM WIG</NavLink></OrderButton>
            <TextField>PLEASE CALL 718-844-6289 TO PLACE AN ORDER BY PHONE</TextField>
        </Background>
    );
}
 
export default Homepage;