import React, { useState, useRef }  from "react";
import styled from "styled-components";
import SimpleReactValidator from 'simple-react-validator';
import EmailService from "../services/EmailService";

const Background = styled.div`
  width: 100%;
  min-height: 70vh;
`;

const Title = styled.p`
    margin-left: 25px;
    color: #C79732;
    font-size: 2rem;
    font-family: "Gruppo" ;
`;

const TextField = styled.p`
    margin-bottom: 5px;
    margin-top: 5px;
    margin-left: 25px;
    color: #C79732;
    font-size: 1.5rem;
    font-family: "Catamaran" ;
    
    a {
        color: #C79732;
    }
`;

const FormElement = styled.div`
  padding-left: 30px;
  padding-bottom: 20px;
  label{
      color: #C79732;
      display:block;
  }
  input{
      background-color: #FFF2CC;
      width: 250px;
      font-size: 1rem;
  }
  textarea{
    background-color: #FFF2CC;
    width: 350px;
    height: 100px;
  }

  .srv-validation-message{
      color: red;
  }
`;

const SubmitButton = styled.div`
    margin-left: 30px;
    margin-bottom: 30px;
    text-align: center;
    width: 250px;
    padding: 5px 5px;
    font-family: "PT Sans", serif;
    font-weight: bold;
    background-color: #C79732;
    font-size: 1rem;
    transition-duration: 0.4s;
    cursor: pointer;
    &:hover {
        background-color: #FFF2CC;
  }
`;

const Contact = () => {
    const validator = useRef(new SimpleReactValidator())
    const [, forceUpdate] = useState();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");
    const [success, setSuccess] = useState("");
    const [btnShow, setBtnShow] = useState(true);

    function submitForm() {
        if (validator.current.allValid()) {
            EmailService.contact(name, email, phone, message).then(() => {
                setSuccess("You have successfully submitted your message, we will get back to you in 1-3 business days.");
                setBtnShow(false);
            },
            (error) => {
                console.log(error);
                setSuccess("There was an error processing your message! Please contact Glamourcustomwigs@gmail.com with your message.");
                setBtnShow(false);
            })
        } else {
          validator.current.showMessages();
          // rerender to show messages for the first time
          // you can use the autoForceUpdate option to do this automatically`
          forceUpdate(1);
        }
      }

    return (  
        <Background>
            <Title>
                CONTACT
            </Title>
            <TextField>
                Boca Raton, FL
            </TextField>
            <TextField>
                Email: <a href="mailto:Glamourcustomwigs@gmail.com">Glamourcustomwigs@gmail.com</a> 
            </TextField>
            <TextField>
                Tel: 718-844-6289
            </TextField>
            <br></br>
            <TextField>
            Contact us by leaving a message below and we will respond to you as soon as possible. 
            </TextField>

        <FormElement>
        <label>Name: </label>
        <input value={name} onChange={(e) => setName(e.target.value)} />
        {validator.current.message('name', name, 'required|alpha_space')}
        </FormElement>

        <FormElement>
        <label>Email: </label>
        <input value={email} onChange={(e) => setEmail(e.target.value)} />
        {validator.current.message('email', email, 'required|email')}
        </FormElement>

        <FormElement>
        <label>Phone: </label>
        <input value={phone} onChange={(e) => setPhone(e.target.value)} />
        {validator.current.message('phone', phone, 'required|phone')}
        </FormElement>

        <FormElement>
        <label>Message: </label>
        <textarea value={message} onChange={(e) => setMessage(e.target.value)} />
        {validator.current.message('message', message, 'max:300')}
        </FormElement>

        {btnShow ? <SubmitButton onClick={submitForm}>Submit</SubmitButton> : <TextField>{success}</TextField>}

        </Background>
    );
}
 
export default Contact;